/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    {
        name: 'GlobalStyles',
        import: () => import('@stories/Widgets/Global/GlobalStyles/GlobalStyles'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'Navigation',
        import: () => import('@stories/Widgets/Global/Navigation/Navigation'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'Footer',
        import: () => import('@stories/Widgets/Global/Footer/Footer'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'FeaturedCards',
        import: () => import('@stories/Widgets/Content/FeaturedCards/FeaturedCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ClientLogosBasic',
        import: () => import('@stories/Widgets/Media/ClientLogosBasic/ClientLogosBasic'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CTACard',
        import: () => import('@stories/Widgets/Content/CTACard/CTACard'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ThirdCardsRow',
        import: () => import('@stories/Widgets/Content/ThirdCardsRow/ThirdCardsRow'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RichText',
        import: () => import('@stories/Widgets/Content/RichText/RichText'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Technologies',
        import: () => import('@stories/Widgets/Content/Technologies/Technologies'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ImageCollage',
        import: () => import('@stories/Widgets/Media/ImageCollage/ImageCollage'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Timeline',
        import: () => import('@stories/Widgets/Content/Timeline/Timeline'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'HomepageHeroBanner',
        import: () => import('@stories/Widgets/Content/HomepageHeroBanner/HomepageHeroBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InnerPageHero',
        import: () => import('@stories/Widgets/Content/InnerPageHero/InnerPageHero'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CaseStudyHero',
        import: () => import('@stories/Widgets/Content/CaseStudyHero/CaseStudyHero'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Testimonial',
        import: () => import('@stories/Widgets/Content/Testimonial/Testimonial'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Awards',
        import: () => import('@stories/Widgets/Content/Awards/Awards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Media',
        import: () => import('@stories/Widgets/Media/Media/Media'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'People',
        import: () => import('@stories/Widgets/Content/People/People'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LocationCards',
        import: () => import('@stories/Widgets/Content/LocationCards/LocationCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SplitContentCard',
        import: () => import('@stories/Widgets/Content/SplitContentCard/SplitContentCard'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ServicesSignposts',
        import: () => import('@stories/Widgets/Content/ServicesSignposts/ServicesSignposts'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'StatsList',
        import: () => import('@stories/Widgets/Content/StatsList/StatsList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FeaturesList',
        import: () => import('@stories/Widgets/Content/FeaturesList/FeaturesList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InsightsDetail',
        import: () => import('@stories/Widgets/Content/InsightsDetail/InsightsDetail'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InsightsHero',
        import: () => import('@stories/Widgets/Content/InsightsHero/InsightsHero'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InsightsCards',
        import: () => import('@stories/Widgets/Content/InsightsCards/InsightsCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InsightsListing',
        import: () => import('@stories/Widgets/Content/InsightsListing/InsightsListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ConnectedGroup',
        import: () => import('@stories/Widgets/Media/ConnectedGroup/ConnectedGroup'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContactForm',
        import: () => import('@stories/Widgets/Forms/ContactForm/ContactForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ClientLogos',
        import: () => import('@stories/Widgets/Content/ClientLogos/ClientLogos'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'GalleryCarousel',
        import: () => import('@stories/Widgets/Media/GalleryCarousel/GalleryCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'WorkListing',
        import: () => import('@stories/Widgets/Content/WorkListing/WorkListing'),
        config: {
            hydrate: 'in-view',
        },
    },
];
